// This source code is licensed under the license found in the
// LICENSE file in the root directory of this source tree.
// 引入antd-ui数据库
// import ImgCrop from 'antd-img-crop';
// 引入Antd - ui库
import 'antd/dist/reset.css';
import { PlusOutlined, ArrowDownOutlined, LoadingOutlined, UploadOutlined, FileZipOutlined, StarOutlined, SearchOutlined } from '@ant-design/icons';
import { Upload, Spin, message, Button, Divider, Modal, Form, Input, MenuProps, Collapse, Menu, Avatar, Progress, Radio, RadioChangeEvent, Alert, Flex, } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
// 引入image标签
import ImageList from './Image'
// 引入axios封装
import axios from "axios";
// 引入本地存储
// import Cookies from 'js-cookie'
import { InferenceSession, Tensor } from "onnxruntime-web";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./assets/scss/App.scss";
import { handleImageScale } from "./components/helpers/scaleHelper";
import { modelScaleProps } from "./components/helpers/Interfaces";
import { onnxMaskToImage } from "./components/helpers/maskUtils";
import { xuuoMaskToImage } from "./components/helpers/secondary";
import { modelData } from "./components/helpers/onnxModelAPI";
import Stage from "./components/Stage";
import ColorTreeSelect from './colorTreeSelect';
import ImageStage from './components/imageStage'
import AppContext from "./components/hooks/createContext";
// 引入App样式
import { textarea, OriginalClauseAssociation, outsideDiv, InsideDiv, TipsDiv, Association, bottomStyle, ImgStyle, buttonStyle } from './AppStyle'
const ort = require("onnxruntime-web");
/* @ts-ignore */
import npyjs from "npyjs";
// Define image, embedding and model paths
// let IMAGE_PATH = "/assets/data/shoe.jpg";
// let IMAGE_EMBEDDING = "/assets/data/shoe.jpg.npy";
const MODEL_DIR = "/model/sam_onnx_quantized_example.onnx";
// 文件上传图片格式设置
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
// 主要方法
const App: React.FC = () => {
  // 文件上传参数设置
  const [loadings, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  // 获取文件上传的图片
  const [uploadImage, setUploadImage] = useState<any>('')
  // 加载效果参数
  const [spinning, setSpinning] = React.useState<boolean>(false);
  const {
    clicks: [clicks],
    image: [image, setImage],
    maskImg: [, setMaskImg],
    maskImgs: [maskImgs, setMaskImgs],
    clickImageArr: [clickImageArr, setClickImageArr],
    clicksSurplusImage: [clicksSurplusImage, setClicksSurplusImage],
    basicsColor: [basicsColor, setBasicsColor],
    auxiliaryColor: [auxiliaryColor, setAuxiliaryColor],
    otherColor: [otherColor, setOtherColor]
  } = useContext(AppContext)!;
  // 正选蒙版数据存放
  const [imageArrList, setImageArrList] = useState<any>([])
  // 反选蒙版数据存放
  const [theImageArrList, setTheImageArrList] = useState<any>([])
  // 反选蒙版点击执行方法
  const [theClickArr, setTheClickArr] = useState<boolean>(false)
  // 生成图片响应式
  const [model, setModel] = useState<InferenceSession | null>(null); // ONNX model
  const [tensor, setTensor] = useState<Tensor | null>(null); // Image embedding tensor
  // The ONNX model expects the input to be rescaled to 1024. 
  // The modelScale state variable keeps track of the scale values.
  const [modelScale, setModelScale] = useState<modelScaleProps | null>(null);
  // 提示词内容部分
  const [words, setWords] = useState<any | null>('');
  // 反向提示词
  const [reverseWords, setReverseWords] = useState<any | null>('');
  // 原款关联数量定义
  const [originalItemNUMBER, setOriginalItemNUMBER] = useState<any | null>(25);
  // 提示词权重
  const [cueWordWeight, setCueWordWeight] = useState<any>(7)
  // 生成数量数量定义
  const [generation, setGeneration] = useState<any | null>(1);
  // 获取原款关联的DOM对象方法
  const OriginalItemDOM = useRef<any | null>(null)
  // 获取提示词权重DOM对象
  const cueWordWeightDOM = useRef<any | null>(null)
  // 获取生成数量的DOM对象方法
  const GenerationDOM = useRef<any | null>(null)
  // 引入文件提醒
  const [messageApi, contextHolder] = message.useMessage();
  // Initialize the ONNX model. load the image, and load the SAM
  // pre-computed image embedding
  // 生成图片列表数据响应式
  const [aIimageDivList, setAIimageDivList] = useState<any>([])
  // 生成图片列表个数响应式参数-----------用于展示图片生成中的样式
  const [aIimageList, setAIimageList] = useState<any>([])
  // 历史列表数据响应式
  const [historyImage, setHistoryImage] = useState<any>([])
  // 对话框参数-----图片父类id
  const [pictographId, setPictographId] = useState<Number>()
  // 对话框参数----图片id
  const [pictographImgId, setPictographImgId] = useState<Number>()
  // 对话框参数----选中的图片路径
  const [dialogImage, setDialogImage] = useState<string>('')
  // 导航栏切换参数
  const [current, setCurrent] = useState('MaskGenerationDiagram');
  // 用户头像
  const [userImage, setUserImage] = useState<String>('')
  // 用户余额
  const [useMoney, setUseMoney] = useState<String>('0')
  // 退出登录对话框参数 
  const [logOut, setLogOut] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 免责声明对话框显示与隐藏参数
  const [liabilityExemption, setLiabilityExemption] = useState(false)
  // 图片生成加载进度
  const [schedule, setSchedule] = useState<any>(0)
  // 模板图片显示与隐藏
  const [maskings, setMasking] = useState<boolean>(false)
  // 为空模版显示与隐藏
  const [emptyImage, setEmptyImage] = useState<boolean>(true)
  // 图片生成加载与隐藏参数
  const [loadingImage, setLoadingImage] = useState<boolean>(false)
  // 正、反选单选框参数响应式数据
  const [radioData, setRadioData] = useState<string>('correctChoice')
  // 文件上传上面的tab栏切换值
  const [tabxz, setTabxz] = useState<Number>(3)
  // 把生成的图片推送到历史记录里面方法
  const getHistoryImage = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    try {
      await axios({
        headers: { 'token': `${token}` },
        method: 'POST',
        url: 'https://api.myaide.cc/api/user/imgList',
        data: {
          type: 3
        }
      }).then((res) => {
        setHistoryImage(res.data.msg)
        userInfo()
      })
    } catch (error) {
    }
  }
  // 监听列表数据方法
  useEffect(() => {
    // 判断加载进入页面方式
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let jubu = urlParams.get('jubu')
    if (jubu) {
      setCurrent('GenerationDiagrams')
    }
    userInfo()
    getHistoryImage()
  }, [])
  // 页面渲染前执行的方法，只加载一次
  useEffect(() => {
    // Initialize the ONNX model
    const initModel = async () => {
      try {
        if (MODEL_DIR === undefined) return;
        const URL: string = MODEL_DIR;
        const model = await InferenceSession.create(URL);
        // model 里面放的是两个数组，并携带一个id : sessionId
        setModel(model);
      } catch (e) {
        console.log(e);
      }
    };
    initModel();
  }, []);
  const loadImage = async (url: URL) => {
    try {
      // 把原生图片渲染到页面
      const img = new Image();
      img.src = url.href;
      // 给原生图片赋予宽高
      img.onload = () => {
        const { height, width, samScale } = handleImageScale(img);
        setModelScale({
          height: height,  // original image height
          width: width,  // original image width
          samScale: samScale, // scaling factor for image which has been resized to longest side 1024
        });
        img.width = width;
        img.height = height;
        setImage(img);
      };
    } catch (error) {
      console.log(error);
    }
  };
  // Decode a Numpy file into a tensor. 
  const loadNpyTensor = async (tensorFile: string, dType: string) => {
    let npLoader = new npyjs();
    const npArray = await npLoader.load(tensorFile);
    const tensor = new ort.Tensor(dType, npArray.data, npArray.shape);
    return tensor;
  };
  // Run the ONNX model every time clicks has changed
  // 页面渲染完毕执行，在clicks数据发生变化时进行在次渲染
  useEffect(() => {
    runONNX();
  }, [clicks]);
  const runONNX = async () => {
    try {
      if (
        model === null ||
        clicks === null ||
        tensor === null ||
        modelScale === null
      )
        return;
      else {
        // Preapre the model input in the correct format for SAM. 
        // The modelData function is from onnxModelAPI.tsx.
        const feeds = modelData({
          clicks,
          tensor,
          modelScale,
        });
        if (feeds === undefined) return;
        // Run the SAM ONNX model with the feeds returned from modelData()
        const results = await model.run(feeds);
        const output = results[model.outputNames[0]];
        // The predicted mask returned from the ONNX model is an array which is 
        // rendered as an HTML image using onnxMaskToImage() from maskUtils.tsx.
        // 选中的图片地址(带标签的)      onnxMaskToImage(output.data, output.dims[2], output.dims[3]) 
        const input: any = output.data
        // 多选功能加入-------正选功能
        let imageTT     //正选参数
        let theImageTT    //反选参数
        const [r, g, b, a] = [255, 255, 255, 255]; // the masks's blue color   选中后的颜色
        const [ther, theg, theb, thea] = [0, 0, 0, 255]; // the masks's blue color   反选的颜色
        const arr = new Uint8ClampedArray(4 * output.dims[2] * output.dims[3]).fill(0);
        let theIndexImageArrList = new Uint8ClampedArray(4 * output.dims[2] * output.dims[3]).fill(0);
        for (let ii = 0; ii < output.data.length; ii++) {
          // 正选图片方法
          if (input[ii] > 0.0) {
            arr[4 * ii + 0] = r;
            arr[4 * ii + 1] = g;
            arr[4 * ii + 2] = b;
            arr[4 * ii + 3] = a;
          } else {
            arr[4 * ii + 0] = 0;
            arr[4 * ii + 1] = 0;
            arr[4 * ii + 2] = 0;
            arr[4 * ii + 3] = 255;
          }
        }
        // 反选图片方法
        for (let i = 0; i < output.data.length; i++) {
          // 反选图片阴影方法
          if (input[i] > 0.0) {
            theIndexImageArrList[4 * i + 0] = ther;
            theIndexImageArrList[4 * i + 1] = theg;
            theIndexImageArrList[4 * i + 2] = theb;
            theIndexImageArrList[4 * i + 3] = thea;
          } else {
            theIndexImageArrList[4 * i + 0] = 255;
            theIndexImageArrList[4 * i + 1] = 255;
            theIndexImageArrList[4 * i + 2] = 255;
            theIndexImageArrList[4 * i + 3] = 255;
          }
        }
        // 点击事件生成多个模版
        if (clickImageArr) {
          // 第一次点击执行
          if (imageArrList.length === 0) {
            setClickImageArr(false)
            setImageArrList(arr)
            imageTT = new ImageData(arr, output.dims[2], output.dims[3])
            setMaskImgs(onnxMaskToImage(imageTT, output.dims[2], output.dims[3]))
          } else {
            setClickImageArr(false)
            let indexImageArrList: any = imageArrList
            for (let index = 0; index < arr.length; index++) {
              if (arr[index] === 255) {
                indexImageArrList[index] = 255
              }
            }
            setImageArrList(indexImageArrList)
            imageTT = new ImageData(indexImageArrList, output.dims[2], output.dims[3])
            setMaskImgs(onnxMaskToImage(imageTT, output.dims[2], output.dims[3]))
          }
        } else {
          imageTT = new ImageData(arr, output.dims[2], output.dims[3])
        }
        //多选方法------反选
        if (theClickArr) {
          if (theImageArrList.length === 0) {
            setTheClickArr(false)
            setTheImageArrList(theIndexImageArrList)
            theImageTT = new ImageData(theIndexImageArrList, output.dims[2], output.dims[3])
          } else {
            setTheClickArr(false)
            let indexImageArrList: any = theImageArrList
            for (let i = 0; i < theIndexImageArrList.length; i++) {
              if (theIndexImageArrList[i] === 0) {
                indexImageArrList[i] = 0
              }
            }
            setTheImageArrList(indexImageArrList)
            theImageTT = new ImageData(indexImageArrList, output.dims[2], output.dims[3])
          }
          setClicksSurplusImage(xuuoMaskToImage(theImageTT, output.dims[2], output.dims[3]))
        }
        let imgHHH = onnxMaskToImage(imageTT, output.dims[2], output.dims[3])
        setMaskImg(imgHHH);
        return imgHHH
      }
    } catch (e) {
      // console.log(e);
    }
  };
  // 蒙版点击事件
  const clickImage = () => {
    setClickImageArr(true)
    setTheClickArr(true)
    runONNX()
  }
  // 生成点击事件
  const buttonScript = async () => {
    setSchedule(0)
    let arr = []
    for (let index = 0; index < generation; index++) {
      arr[index] = index + 1
    }
    await setAIimageList(arr)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    if (!maskImgs) {
      // 未选中模版
      messageApi.open({
        type: 'warning',
        content: '请先上传图片并点击生成区域！',
      });
    } else {
      setMasking(false)
      setLoadingImage(true)
      // 获取Base64 位图片流地址
      let src: any
      if (radioData === 'correctChoice') {
        src = maskImgs?.src
      } else if (radioData === 'reverseElection') {
        src = clicksSurplusImage?.src
      }
      // 将Base 64位图片转为文件流方法
      let arr = src.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr: any = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 转化后的文件流   
      let file = new File([u8arr], 'tushengtu.jpeg', { type: mime })
      await axios({
        headers: { 'token': `${token}`, "Content-Type": "multipart/form-data" },
        method: 'POST',
        url: 'https://api.myaide.cc/api/common/upload',
        data: {
          file: file
        }
      }).then((res: any) => {
        let color = basicsColor.slice(1).toLowerCase().toUpperCase()
        let assist_color = auxiliaryColor.slice(1).toLowerCase().toUpperCase()
        let other_color = otherColor.slice(1).toLowerCase().toUpperCase()
        // let color = '<' + zz + '>'
        // 返回前面图片拼接地址     https://api.myaide.cc/img/   +   res
        // AI生成方法
        axios({
          headers: { 'token': `${token}` },
          method: 'POST',
          url: 'https://api.myaide.cc/api/user/sc',
          data: {
            init_image: uploadImage,
            mask_image: res.data.data.url,
            prompt: words,
            negative_prompt: reverseWords,
            association: originalItemNUMBER,
            pay: 10 * generation,
            guidance_scale: cueWordWeight,
            num: generation,
            color,
            assist_color,
            other_color,
          }
        }).then((res) => {
          if (res.data.code === 0) {
            messageApi.open({
              type: 'error',
              content: res.data.msg,
            });
            setLoadingImage(false)
            setMasking(true)
          } else {
            // 点击生成获取到task_id
            getImageAI(res.data.data.data.task_id)
          }
        })
      })
    }
  }
  // 生成图片轮询------------轮询成功后吧生成的图片进行上传
  const getImageAI = (id: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    axios({
      headers: { 'token': `${token}` },
      method: 'post',
      url: 'https://api.myaide.cc/api/user/getnewimg',
      data: {
        task_id: id
      }
    }).then(async (code) => {
      if (code.data.code === 0) {
        messageApi.open({
          type: 'warning',
          content: '生成失败，请重新生成！',
        });
        setLoadingImage(false)
        setMasking(true)
      } else if (code.data.data.datas.status != 'SUCCESS') {
        // 图片加载进度中
        setSchedule(code.data.data.jindu)
        getImageAI(id)
      } else {
        // setImages(code.data.data.data.img)
        if (!code.data.data.datas) {
          messageApi.open({
            type: 'warning',
            content: '生成失败，请重新生成！',
          });
          axios({
            headers: { 'token': `${token}` },
            method: 'post',
            url: 'https://api.myaide.cc/api/user/backmoney',
            data: {
              pay: 10 * generation
            }
          })
          setLoadingImage(false)
          setMasking(true)
        } else if (code.data.data.datas.data.img) {
          // 图片生成进度达到100
          setSchedule(code.data.data.jindu)
          setLoadingImage(false)
          let imgs: any = []
          for (let i = 0; i < code.data.data.datas.data.img.length; i++) {
            imgs[i] = 'https://img.myaide.cc/' + code.data.data.datas.data.img[i]
          }
          // 将生成的图片存储起来
          setAIimageDivList(imgs)
          // 将生成的图片记录推送到服务器上
          await axios({
            headers: { 'token': `${token}` },
            method: 'POST',
            url: 'https://api.myaide.cc/api/user/pictographAdd',
            data: {
              association: originalItemNUMBER,
              num: generation,
              chic: 1,
              content: uploadImage,
              guidance_scale: '',
              hei: '512',
              wei: "512",
              img: imgs,
              style_id: 0,
              type: 3,
              word: words,
              pay: generation * 10
            }
          }).then((shuju) => {
            setMasking(true)
            messageApi.open({
              type: 'success',
              content: '生成成功！',
            });
            getHistoryImage()
          })
        }
      }
    })
  }
  // 提示词内容
  const wordsScript = (e: any) => {
    setWords(e.target.value)
  }
  // 反转提示词
  const reverseScript = (e: any) => {
    setReverseWords(e.target.value)
  }
  // 原款关联度方法
  const originalItem = (e: any) => {
    OriginalItemDOM.current.style.width = e.nativeEvent.offsetX + 'px'
    let num: any = e.nativeEvent.offsetX / 3
    let bb = parseInt(num)
    setOriginalItemNUMBER(bb + 1)
  }
  // 提示词权重方法
  const cueWordWeightItem = (e: any) => {
    cueWordWeightDOM.current.style.width = e.nativeEvent.offsetX + 'px'
    let num: any = e.nativeEvent.offsetX / 17
    let bb = parseInt(num)
    setCueWordWeight(bb + 1)
  }
  // 生成数量方法
  const generationScript = (e: any) => {
    let num: any = e.nativeEvent.offsetX / 3
    let bb = parseInt(num)
    if (bb <= 25) {
      bb = 25
    } else if (25 < bb && bb <= 50) {
      bb = 50
    } else if (50 < bb && bb <= 75) {
      bb = 75
    } else {
      bb = 100
    }
    GenerationDOM.current.style.width = bb * 3 + 'px'
    setGeneration(bb / 25)
  }
  // 封装循环请求   回去npy文件
  const GetNPY = (val: string, image: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    setMaskImgs(null)
    axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: 'https://api.myaide.cc/api/user/getmodel',
      data: {
        task_id: val
      }
    }).then((res: any) => {
      if (res.data.data.status === 'STARTED') {
        GetNPY(val, image)
      } else if (res.data.data.status === 'SUCCESS') {
        // 获取NPY文件
        // setIMAGE_EMBEDDING('http://img.myaide.cc/' + res.data.data.data);
        let url = new URL(image, location.origin)
        let pny = 'https://img.myaide.cc/' + res.data.data.data
        loadImage(url)
        Promise.resolve(loadNpyTensor(pny, "float32")).then(
          (embedding) => setTensor(embedding)
        );
        messageApi.open({
          type: 'success',
          content: '图片蒙版生成成功',
        });
        setSpinning(false);
        setMasking(true)
        // 上传成功图片后吧为空图片隐藏
        setEmptyImage(false)
      }
    })
  }
  // 文件上传方法
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
        setUploadImage(info.fileList[0].response.data.url)
        let src: any = info.fileList[0].response.data.url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let token = urlParams.get('SpringOfMemory')
        axios({
          headers: { 'token': `${token}` },
          method: 'POST',
          url: 'https://api.myaide.cc/api/user/gettask',
          data: {
            img: src
          }
        }).then((res: any) => {
          setSpinning(true);
          // 任务id   res.data.data.data.task_id
          GetNPY(res.data.data.data.task_id, info.fileList[0].response.data?.fullurl)
        })
      });
    }
  };
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loadings ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 27, fontSize: '12px' }}>点击上传文件</div>
    </button>
  );
  // 点击上传图片方法
  const props: UploadProps = {
    action: 'https://api.myaide.cc/api/common/upload',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setImageUrl(info.file.response.data.fullurl);
        setUploadImage(info.file.response.data.url)
        let src: any = info.file.response.data.url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let token = urlParams.get('SpringOfMemory')
        axios({
          headers: { 'token': `${token}` },
          method: 'POST',
          url: 'https://api.myaide.cc/api/user/gettask',
          data: {
            img: src
          }
        }).then((res: any) => {
          setTheImageArrList([])
          setImageArrList([])
          setSchedule(0)
          setSpinning(true);
          GetNPY(res.data.data.data.task_id, info.file.response.data?.fullurl)
        })
      }
    },
  };
  // 图片列表里面点击重新生成方法
  const anewImage = (val: string) => {
    let src = val.slice(21)
    setUploadImage(src)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: 'https://api.myaide.cc/api/user/gettask',
      data: {
        img: src
      }
    }).then((res: any) => {
      setSchedule(0)
      setSpinning(true);
      GetNPY(res.data.data.data.task_id, val)
    })
  }
  // 点击下载图片
  const download = (dowurl: any) => {
    var image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let contexts: any = canvas.getContext("2d");
      contexts.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = dowurl;
  }
  // 收藏到图库方法
  const gallery = (pictograph_id: number, pictograph_img_id: number, img: string) => {
    setPictographId(pictograph_id)
    setPictographImgId(pictograph_img_id)
    setDialogImage(img)
    setIsModalOpen(true);
  }
  // 对话框关闭方法
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // 确定按钮点击方法
  const onFinish = async (values: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    await axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: 'https://api.myaide.cc/api/user/galleryAdd',
      data: {
        img: dialogImage,
        pictograph_id: pictographId,
        pictograph_img_id: pictographImgId,
        ...values
      }
    }).then((res) => {
      messageApi.open({
        type: 'success',
        content: '保存成功！',
      });
      setIsModalOpen(false);
    })
  };
  // 收藏点击事件
  const collection = async (type: number) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    await axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: "https://api.myaide.cc/api/user/addCollect",
      data: {
        id: type
      }
    }).then((res) => {
      messageApi.open({
        type: 'success',
        content: '操作成功！',
      });
      getHistoryImage()
    })
  }
  // 表单数据校验
  type FieldType = {
    label?: string;
  };
  // 文件上传TAB跳转切换方法
  const tabxzClick = (val: number) => {
    if (val === 1) {
      window.open('https://www.myaide.cc/#/idea', '_self')
    } else if (val === 2) {
      window.open('https://www.myaide.cc/#/swjx', '_self')
    }

  }
  // 导航栏点击事件
  const MenuClick: MenuProps['onClick'] = (e) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    setCurrent(e.key);
    if (e.key != 'MaskGenerationDiagram') {
      if (e.key != 'GenerationDiagrams') {
        window.open(e.key, '_self')
      }
    } 
  };
  // 导航栏数据
  const items: MenuProps['items'] = [
    {
      label: '首页',
      key: ' https://www.myaide.cc/#/',
    },
    {
      label: '灵感池',
      key: ' https://www.myaide.cc/#/gallery',
    },
    {
      label: '创意重构',
      key: 'https://www.myaide.cc/#/idea',
    },
    {
      label: 'AI百宝箱',
      key: 'https://www.myaide.cc/#/bbx',
    },
    {
      label: '局部重构',
      key: 'MaskGenerationDiagram',
      // https://www.myaide.cc/#/idea
    },
  ];
  // 请求用户信息方发
  const userInfo = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: "https://api.myaide.cc/api/user/index",
    }).then((res) => {
      setUserImage(res.data.data.avatar)
      setUseMoney(res.data.data.money)
    })
  }
  // 输入框搜索事件
  const Searching = (e: any) => {
    // 点击Enter
    if (e.key === 'Enter') {
      window.open(`https://tushengtu.myaide.cc/#/gallerys/${e.target.value}`, '_self')
    }
  }
  // 前往个人中心页面
  const GoUser = () => {
    window.open('https://tushengtu.myaide.cc/#/user', '_self')
  }
  // 底部点击功能跳转
  const JumpWeb = () => {
    window.open('https://tushengtu.myaide.cc/#/')
  }
  // 点击退出方法
  const Exit = () => {
    setLogOut(true)
  }
  // 退出登录对话框点击确定方法
  const subirClick = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('SpringOfMemory')
    await axios({
      headers: { 'token': `${token}` },
      method: 'POST',
      url: 'https://api.myaide.cc/api/user/logout'
    }).then((res) => {
      setLogOut(false)
      window.open('https://tushengtu.myaide.cc/#/login', '_self')
    })
  }
  // 退出登录对话框点击取消方法
  const logOutClick = () => {
    setLogOut(false)

  }
  const logOutClicks = () => {
    setLiabilityExemption(false)
  }
  // 免责声明
  const liabilityExemptionClick = () => {

    setLiabilityExemption(true)
  }
  // 正、反选改变方法
  const RadioFN = (e: RadioChangeEvent) => {
    setRadioData(e.target.value)
  }
  // 蒙版取消方法
  const maskFN = () => {
    setMaskImg(null)
    setTheImageArrList([])
    setImageArrList([])
  }
  return <>
    {contextHolder}
    <Modal className='ModelCss' centered={false} style={{ marginTop: "-6%", }} title="" width='100%' open={liabilityExemption} onCancel={logOutClicks}>
      <div style={{ width: "1300px", margin: '0  auto', padding: '64px 0' }}>
        <h2 style={{ fontSize: "20px", textAlign: "center", marginBottom: '20px', color: '#000' }}>《杭州艺境智能科技有限公司免责声明》</h2>
        <p style={{ color: "#000", fontSize: "16px", lineHeight: "30px", marginBottom: "20px" }}>在本网站/应用程序/项目（以下简称"本项目"）上使用的图片、图像或其他媒体素材（以下简称"素材"）可能来自于互联网，部分素材可能会涉及版权争议或侵犯他人的知识产权。我们已经尽力确保在本项目中使用的素材符合相关法律法规，但无法对其完全确保合法性。 如果您认为本项目中的某些素材侵犯了您的版权或知识产权，您可以通过以下方式与我们联系：</p>
        <p style={{ color: "#000", fontSize: "16px", lineHeight: "30px" }}>通过电子邮件发送投诉至：service@myaide.cc 在电子邮件主题中注明"版权投诉 - [具体素材描述]"。 在电子邮件正文中提供您的联系信息、侵权素材的明确描述以及相关证据，以便我们更好地处理您的投诉。 一旦我们收到您的投诉，我们将会尽快采取行动，包括但不限于下线涉及侵权或争议的素材，以确保维护知识产权的合法权益。请理解，在某些情况下，我们可能需要一些时间来调查并采取适当的措施。 请注意，本免责声明不构成对侵权行为的认可或免责。我们尊重知识产权，将积极采取措施来解决侵权问题。</p>
      </div>

    </Modal>
    <Modal centered={true} style={{ marginTop: "-12%" }} width='30%' title="消息提示" open={logOut} onCancel={logOutClick}>
      <p> <img style={{ width: '22px', height: '22px' }} src='./assets/image/warnings.svg'></img> 你确定要退出登录吗？</p>
      <div className='ButtomButton' style={{ marginTop: '12px', width: '100%', display: 'flex', justifyContent: 'end' }}>
        <Button onClick={logOutClick} className='Cancel' >
          取消
        </Button>
        <Button onClick={subirClick} type="primary" className='determine' >
          确定
        </Button>
      </div>
    </Modal>
    <Modal centered={false} width='45%' title="保存到公共图库" open={isModalOpen} onCancel={handleCancel} >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="保存图片"
        >
          <img src={dialogImage} />
        </Form.Item>
        <Form.Item<FieldType>
          label="设置标签"
          name="label"
          rules={[{ required: true, message: '请输入标签名称！' }]}
        >
          <Input className='fromInputCss' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button className='MapStorageCss' type="primary" htmlType="submit">
            确定保存
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    {/* 顶部导航栏部分 */}
    <div className='NavClasss'>
      <div className='NavClassDiv'>
        <img style={{ width: "174px" }} src="./assets/image/logo.svg" />
        <Input onKeyDown={(e) => Searching(e)} style={{ width: "270px", height: "42px" }} size="large" placeholder="请输入搜索关键词" prefix={<SearchOutlined style={{ color: "#c2cad4" }} />} />
        {/* 右侧部分 */}
        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '700px' }}>
          <Menu style={{ width: "480px", backgroundColor: '#000', color: "#fff", fontSize: '17px', fontWeight: "400" }} onClick={MenuClick} selectedKeys={[current]} mode="horizontal" items={items} />
          {/* 个人信息部分 */}
          <div onClick={GoUser} style={{ display: "flex", alignItems: "center", position: 'relative' }}>
            {/* 头像部分 */}
            <Avatar style={{ position: 'absolute', left: '-28px' }} size={50} src={userImage}></Avatar>
            {/* 金额部分 */}
            <div style={{ width: '140px', height: "40px", display: 'flex', justifyContent: "center", alignItems: 'center', border: "1px solid #999", borderRadius: '20px', }}>
              <img style={{ width: '18px', height: '18px' }} src="./assets/image/price.png" alt="" />
              <span style={{ fontSize: '14px' }}>{useMoney}</span>
            </div>
          </div>
          {/* 退出 */}
          <div onClick={Exit} style={{ fontSize: '18px' }}>退出</div>
        </div>
      </div>
    </div>
    {/* 中间内容部分 */}
    <div className="divAll">
      {/* 左边内容部分 */}
      <Spin tip="图片蒙版生成中..." size="large" spinning={spinning} fullscreen />
      <div className="divLift">
        <dl style={{ color: '#fff', position: "relative" }}>
          <div style={{ position: 'absolute', right: '10px', top: "80px", zIndex: "100" }}>
            <Upload {...props}>
              <Button icon={<UploadOutlined style={{ fontSize: '20px' }} />}></Button>
            </Upload>
          </div>
          <div>
            <div style={{ display: 'flex', marginTop: '15px', marginBottom: '30px', fontSize: '16px', color: 'rgba(246, 246, 246, 1)', textAlign: 'center' }}>
              <div onClick={() => tabxzClick(1)} id={tabxz === 1 ? 'tabxzActiveStyle' : ''} className='tabxzStyle' style={{ width: '140px', height: '46px', borderRadius: '4px', marginRight: '21px', lineHeight: '46px', border: '1px solid rgba(153, 153, 153, 1)' }}>整体重构</div>
              <div onClick={() => tabxzClick(2)} id={tabxz === 2 ? 'tabxzActiveStyle' : ''} className='tabxzStyle' style={{ width: '140px', height: '46px', borderRadius: '4px', marginRight: '21px', lineHeight: '46px', border: '1px solid rgba(153, 153, 153, 1)' }}>思维具现</div>
              <div id={tabxz === 3 ? 'tabxzActiveStyle' : ''} className='tabxzStyle' style={{ width: '140px', height: '46px', borderRadius: '4px', marginRight: '21px', lineHeight: '46px', border: '1px solid rgba(153, 153, 153, 1)' }}>局部重构</div>
            </div>
          </div>
          <Collapse
            defaultActiveKey={['1']}
            items={[{
              key: '1', label: '上传素材', children: <div>
                {!image && <Upload
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://api.myaide.cc/api/common/upload"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? <div style={{ height: '250px' }}><img src={imageUrl} alt="avatar" style={{ height: "250px" }} /></div> : uploadButton}
                </Upload>}
                <div onClick={maskFN} style={{ marginTop: '20px' }}>
                  <Stage />
                  {maskImgs && <Alert
                    style={{ marginTop: '10px', width: '99%' }}
                    message="点击图片可重新选择模版"
                    type="warning"
                    closable
                  />}
                </div>
              </div>
            }]}
          />
        </dl>
        <dl>
          <Radio.Group style={{ margin: "10px 0" }} onChange={RadioFN} value={radioData}>
            <Radio style={{ color: "#fff", marginLeft: "16px" }} value={'correctChoice'}>生成选择区域</Radio>
            <Radio style={{ color: "#fff", marginLeft: "40px" }} value={'reverseElection'}>保留选择区域</Radio>
          </Radio.Group>
        </dl>
        <dl>
          <Collapse defaultActiveKey={['1']} items={[{ key: '1', label: '提示词', children: <textarea className='Tips' onChange={(e) => wordsScript(e)} value={words} style={textarea} placeholder="支持一次录入多个并用逗号区隔；增加某提示词权重时，加以（），（）层级越多代表权重越高"></textarea> }]} />
        </dl>
        <dl>
          <Collapse defaultActiveKey={['1']} items={[{ key: '1', label: '反向提示词', children: <textarea className='directionTips' onChange={(e) => reverseScript(e)} value={reverseWords} style={textarea} placeholder="请输入反向提示词"></textarea> }]}></Collapse>
        </dl>
        {/* 颜色 */}
        <dl>
          <Collapse defaultActiveKey={['1']} items={[{
            key: '1', label: "颜色", children: <div>
              <ColorTreeSelect />
            </div>
          }]}></Collapse>
        </dl>
        {/* 生成设置 */}
        <dl>
          <Collapse defaultActiveKey={['1']} items={[{
            key: '1', label: '生成设置', children: <div>
              <dd>
                <div style={Association}>原款关联</div>
                <div style={OriginalClauseAssociation}>
                  <div onClick={(e) => originalItem(e)} style={outsideDiv}>
                    <div ref={OriginalItemDOM} style={InsideDiv}></div>
                  </div>
                  <div>{originalItemNUMBER}</div>
                </div>
              </dd>
              <dd>
                <div style={Association}>提示词权重</div>
                <div style={OriginalClauseAssociation}>
                  <div onClick={(e) => cueWordWeightItem(e)} style={outsideDiv}>
                    <div ref={cueWordWeightDOM} style={TipsDiv}></div>
                  </div>
                  <div>{cueWordWeight}</div>
                </div>
              </dd>
              <dd>
                <div style={Association}>生成数量</div>
                <div style={OriginalClauseAssociation}>
                  <div onClick={(e) => generationScript(e)} style={outsideDiv}>
                    <div ref={GenerationDOM} style={InsideDiv}></div>
                  </div>
                  <div>{generation}</div>
                </div>
              </dd>
            </div>
          }]}></Collapse>
        </dl>
        {/* 底部数量和生成按钮 */}
        <dl style={{ marginBottom: "64px" }}>
          <dd>
            <div style={bottomStyle}>
              {/* 左侧部分样式 */}
              <div>
                <span>实付：{generation * 10}</span>
                <img style={ImgStyle} src="./assets/image/price.png" />
              </div>
              {/* 右侧部分样式     */}
              <Button onClick={buttonScript} style={buttonStyle} loading={loadingImage} >生成</Button>
            </div>
          </dd>
          <span onClick={liabilityExemptionClick} style={{ color: "red", fontSize: '14px' }}>免责声明</span>
        </dl>
      </div>
      <div className="divRight">
        {emptyImage && <img style={{ margin: "100px 300px" }} src="./assets/image/nodata.png" alt="" />}
        {maskings && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div onClick={clickImage}><ImageStage></ImageStage></div></div>}
        {loadingImage && <div style={{ display: 'flex', alignItems: 'center' }}>{aIimageList.map((item: string, index: number) => {
          return (
            <div key={index} style={{ marginLeft: '32px', border: "1px solid #fff", width: "200px", height: "200px", textAlign: 'center', paddingTop: "25px", color: "#fff", marginTop: '32px' }}>
              <Progress trailColor="#fff" type="circle" percent={schedule} />
            </div>
          )
        })}</div>
        }
        <div style={{ display: 'flex', width: '880px', marginLeft: '10px', marginTop: '32px' }}>
          {aIimageDivList.map((item: string, index: number) => {
            return (
              <div key={index} style={{ marginLeft: "32px" }}>
                <ImageList ZAimage={item}></ImageList>
              </div>
            )
          })}
        </div>
        <div >
          {historyImage.map((item: any, index: number) => {
            return (
              // 一级循环
              <div key={index} style={{ marginBottom: '20px' }}>
                <Divider style={{ width: "180px", paddingLeft: '20px', paddingRight: '20px', color: '#000' }}>{item.createtime}</Divider>
                <div style={{ display: 'flex', width: '880px', marginLeft: '10px', marginTop: '32px' }}>
                  {/* 二级循环 */}
                  {item.imagelist.map((ele: any, i: number) => {
                    return (
                      <div key={i} style={{ marginLeft: "32px" }}>
                        <ImageList ZAimage={ele}></ImageList>
                        <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'end' }}>
                          <img onClick={() => anewImage(ele)} style={{ width: '20px', marginLeft: '18px' }} src="./assets/image/share.svg" />
                          <ArrowDownOutlined onClick={() => download(ele)} style={{ color: "#999999", fontSize: "20px", marginLeft: '18px' }} />
                          <FileZipOutlined onClick={() => gallery(item.id, item.image[i].id, ele)} style={{ color: "#999999", fontSize: "18px", marginLeft: '18px' }} />
                          {item.image[i].is_collect == 0 ? <StarOutlined onClick={() => collection(item.image[i].id)} style={{ color: "#999999", fontSize: "20px", marginLeft: '18px' }} /> :
                            <img onClick={() => collection(item.image[i].id)} style={{ width: '20px', marginLeft: '18px' }} src="./assets/image/collection.svg" />}
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
      </div>
    </div>
    {/* 底部内容部分 */}
    <div className='buttomClass'>
      <div className='buttomClassDiv'>
        <p style={{ margin: '10px 0' }}>地址：浙江省杭州市萧山区宁围街道民和路479号国泰科技大厦801室<span style={{ marginLeft: '16px' }}>电话：191 5780 8289</span><span style={{ marginLeft: '16px' }}>邮箱：service@myaide.cc</span> </p>
        <p style={{ margin: '10px 0' }}>杭州艺境智能科技有限公司<span style={{ marginLeft: '16px' }}>版权所有</span></p>
        <p style={{ margin: '10px 0' }}> <img src="./assets/image/g.jpg" alt="" /><span onClick={JumpWeb} style={{ color: 'rgb(147,147,147)', marginLeft: "16px" }}>浙公网安备 33010902003668号</span><a style={{ color: '#fff', marginLeft: "16px" }} href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2023018892号</a></p>
      </div>
    </div>
  </>
};
export default App;