// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxs {
    width: 80px !important;
    height: 30px !important;
}

.boxs {

    border-radius: 6px;
}

.boxssystem {
    width: 40px !important;
    height: 30px !important;
}

.boxssystem {

    border-radius: 6px;
}

.ant-select-selector {
    width: 100px !important;
    height: 36px !important;
    background-color: black !important;
}

.colorSelectStyle {
    display: inline-block;
    border: 1px solid #fff;
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    position: relative;
}

.colorSelectStyle:hover .BasicsIcon {
        opacity: 1 !important;
    }

.colorSelectStyle:hover .AuxiliaryIcon {
        opacity: 1 !important;
    }

.colorSelectStyle:hover .OtherIcon {
        opacity: 1 !important;
    }

.ant-cascader-menu {
    height: 330px !important;
}

.ant-cascader-menu {
    background-color: #767575;
    opacity: .9;
}

.ant-cascader-menu-item.ant-cascader-menu-item-expand.ant-cascader-menu-item-active {
    background-color: #f6fbff !important;
}`, "",{"version":3,"sources":["webpack://./src/colorTreeSelect.scss"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;AAG3B;;AALA;;IAII,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAG3B;;AALA;;IAII,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,kCAAkC;AACtC;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAGI;QACI,qBAAqB;IACzB;;AAEA;QACI,qBAAqB;IACzB;;AAEA;QACI,qBAAqB;IACzB;;AAGJ;IAEI,wBAAwB;AAE5B;;AAJA;IACI,yBAAyB;IAEzB,WAAW;AACf;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".boxs {\r\n    width: 80px !important;\r\n    height: 30px !important;\r\n\r\n    border-radius: 6px;\r\n}\r\n\r\n.boxssystem {\r\n    width: 40px !important;\r\n    height: 30px !important;\r\n\r\n    border-radius: 6px;\r\n}\r\n\r\n.ant-select-selector {\r\n    width: 100px !important;\r\n    height: 36px !important;\r\n    background-color: black !important;\r\n}\r\n\r\n.colorSelectStyle {\r\n    display: inline-block;\r\n    border: 1px solid #fff;\r\n    width: 100px;\r\n    height: 36px;\r\n    line-height: 36px;\r\n    text-align: center;\r\n    color: #fff;\r\n    border-radius: 4px;\r\n    position: relative;\r\n}\r\n\r\n.colorSelectStyle:hover {\r\n    .BasicsIcon {\r\n        opacity: 1 !important;\r\n    }\r\n\r\n    .AuxiliaryIcon {\r\n        opacity: 1 !important;\r\n    }\r\n\r\n    .OtherIcon {\r\n        opacity: 1 !important;\r\n    }\r\n}\r\n\r\n.ant-cascader-menu {\r\n    background-color: #767575;\r\n    height: 330px !important;\r\n    opacity: .9;\r\n}\r\n\r\n.ant-cascader-menu-item.ant-cascader-menu-item-expand.ant-cascader-menu-item-active {\r\n    background-color: #f6fbff !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
