// 提示词样式
export const cueWords = {
    marginTop: '20px',
    marginBottom: '10px'
}
// 文本域样式
export const textarea = {
    backgroundColor: '#000',
    height: '100px',
    width: '350px',
    border: '1px solid #bab8b8',
    borderRadius: '5px',
    padding: '5px',
}
// 反向提示词
export const ReversecueWords = {
    marginTop: '20px',
    marginBottom: '10px'
}
// 生产设置样式
export const GenerationSet = {
    marginTop: '20px',
    marginBottom: '10px'
}
// 
export const Association = {
    marginTop: '20px',
}
// 进度条样式
export const OriginalClauseAssociation = {
    marginTop: '5px',
    width: '350px',
    height: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}
// 外部进度条样式
export const outsideDiv = {
    margin: "0",
    padding: '0',
    width: "300px",
    height: '12px',
    border: '1px solid #bab8b8',
    borderRadius: '5px',
    marginTop: '5px',
}
// 内部进度条
export const InsideDiv = {
    margin: "0",
    padding: '0',
    width: '76px',
    height: '10px',
    borderRadius: '4px',
    backgroundColor: '#209dd9'
}
// 提示词权重进度条样式
export const TipsDiv = {
    margin: "0",
    padding: '0',
    width: '110px',
    height: '10px',
    borderRadius: '4px',
    backgroundColor: '#209dd9'
}
// 底部按钮样式
export const bottomStyle = {
    marginTop: '50px',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}
// 金币图片样式
export const ImgStyle = {
    width: '22px',
    height: '22px',
    marginLeft: '8px',
}
// 按钮样式
export const buttonStyle = {
    width: '120px',
    height: '32px',
    borderRadius: '5px',
    background: 'rgba(61, 141, 252, 1)',

}
// 文件上传上部taB框切换前样式
export const tabxzStyle = {
    border:'1px solid #fff !important',
}