import React from 'react';
import { Image } from 'antd';

const ImgageList = (props:any) => (


    <Image
        width={180}
        src={props.ZAimage}
    />

)



export default ImgageList;