import React, { useContext, useEffect, useState } from 'react';
import { Cascader, Flex } from 'antd';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons'
// 引入axios封装
import axios from "axios";
import './colorTreeSelect.scss'
import AppContext from "./components/hooks/createContext";
const colorTreeSelect = () => {
    // 树状结构数据
    const [optionTree, setOptionTree] = useState<any>([])
    useEffect(() => {
        getColorFN()
    }, [])
    const {
        basicsColor: [basicsColor, setBasicsColor],
        auxiliaryColor: [auxiliaryColor, setAuxiliaryColor],
        otherColor: [otherColor, setOtherColor]
    } = useContext(AppContext)!;
    const getColorFN = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let token = urlParams.get('SpringOfMemory')
        await axios({
            headers: { 'token': `${token}` },
            url: 'https://api.myaide.cc/api/user/get_color',
            // url: 'https://www.myaide.cc/#/api/user/get_color',
            method: 'POST'
        }).then((res) => {
            let SystemofColor = ''
            // 树状数据接收
            let TreeListData: any = []
            let list = res.data.data
            list.forEach((ele: any, index: number) => {
                if (index === 0) {
                    SystemofColor = '黑白色系'
                } else if (index === 1) {
                    SystemofColor = '橙色系'
                } else if (index === 2) {
                    SystemofColor == '黄色系'
                } else if (index === 3) {
                    SystemofColor = '红色系'
                } else if (index === 4) {
                    SystemofColor = '紫色系'
                } else if (index === 5) {
                    SystemofColor = '绿色系'
                } else if (index === 6) {
                    SystemofColor = '蓝色系'
                } else if (index === 7) {
                    SystemofColor = '米色系'
                }
                TreeListData.push({
                    value: index,
                    label: <div style={{ display: 'flex', alignItems: 'center' }}><div className='boxssystem' style={{ backgroundColor: ele[0].color }}></div><span style={{ color: '#000', marginLeft: '8px', marginRight: '8px' }}>{SystemofColor}</span></div>,
                    children: []
                })
                ele.forEach((item: any) => {
                    TreeListData[index].children.push({
                        value: item.color,
                        label: <div className='boxs' style={{ backgroundColor: item.color }}></div>
                    })
                });
            })
            setOptionTree(TreeListData)
        })
    }
    interface Option {
        value: string | number;
        label: any;
        children?: Option[];
    }
    const options: Option[] = [
        ...optionTree
    ];
    // 基础颜色选择
    const onChangeBasics = (value: any) => {
        setBasicsColor(value[1])
    };
    // 辅助色选择
    const onChangeAuxiliary = (value: any) => {
        setAuxiliaryColor(value[1])
    };
    // 其它色选择
    const onChangeOther = (value: any) => {
        setOtherColor(value[1])
    };
    // 基础色重选方法
    const BasicsDelete = (e: any) => {
        // 阻止冒泡事件方法
        e.stopPropagation()
        setBasicsColor('')
    }
    // 辅助色取消方法
    const AuxiliaryDelete = (e: any) => {
        // 阻止冒泡事件方法
        e.stopPropagation()
        setAuxiliaryColor('')
    }
    // 其它色取消方法
    const OtherDelete = (e: any) => {
        e.stopPropagation()
        setOtherColor('')
    }
    return (<>
        <div style={{ margin: '15px 0px' }}>
            <Flex justify='space-between'>
                <Cascader style={{ backgroundColor: '#000' }} options={options} onChange={onChangeBasics} >
                    <span style={{ backgroundColor: basicsColor }} className='colorSelectStyle'>{!basicsColor && '基础色'}
                        {basicsColor && <CloseCircleOutlined className='BasicsIcon' onClick={(e) => BasicsDelete(e)} style={{ position: 'absolute', right: '4px', top: "4px", color: '#fff', opacity: 0, fontSize: "16px" }} />}
                    </span>
                </Cascader>
                <Cascader style={{ backgroundColor: '#000' }} options={options} onChange={onChangeAuxiliary} >
                    <span style={{ backgroundColor: auxiliaryColor }} className='colorSelectStyle'>{!auxiliaryColor && '辅助色'}
                        {auxiliaryColor && <CloseCircleOutlined className='AuxiliaryIcon' onClick={(e) => AuxiliaryDelete(e)} style={{ position: 'absolute', right: '4px', top: "4px", color: '#fff', opacity: 0, fontSize: "16px" }} />}
                    </span>
                </Cascader>
                <Cascader style={{ backgroundColor: '#000' }} options={options} onChange={onChangeOther} >
                    <span style={{ backgroundColor: otherColor }} className='colorSelectStyle'>{!otherColor && '其它色'}
                        {otherColor && <CloseCircleOutlined className='OtherIcon' onClick={(e) => OtherDelete(e)} style={{ position: 'absolute', right: '4px', top: "4px", color: '#fff', opacity: 0, fontSize: "16px" }} />}
                    </span>
                </Cascader>
            </Flex>
        </div>
    </>)
}
export default colorTreeSelect